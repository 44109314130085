@import 'mixin.scss';

.container{
    // background: linear-gradient(112.51deg, rgba(160, 86, 43, 0.2) 18.92%, rgba(201 166 145, 0.57) 107.05%), #8a5e35;
    position: relative;
  
    @include devices(desktop){
        padding: var(--section-wing);
        padding: 0;
        // padding-top: var(--section-gap-desktop);
        // padding-bottom: var(--section-gap-desktop);

    }

  
}

.header_info{
    padding-bottom: var(--section-gap-mobile);
    // padding-top: var(--section-gap-mobile);
    padding-top: 72px;
    @include devices(mobileandtablet){
        padding: var(--section-wing);
        padding-bottom: var(--section-gap-mobile);
        padding-top: var(--section-gap-mobile);
        position: relative;
        z-index: 1;
    }

    @include devices(desktop){
        // padding-top: var(--section-gap-desktop);
        margin: auto;
        display: grid;
        max-width: var(--container-med);
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 40px;
    }

    h2{
        font-size: var(--h1-size);
        line-height: 1em;
        margin-bottom: 8px;
        text-align: right;
        letter-spacing: -2px;
        z-index: 1;
    }
    h3 {
        font-weight: 700;
        font-size: var(--p-size);
        line-height: 1.5;
        letter-spacing: -.5px;
        margin-bottom: 8px;
        z-index: 1;

    }

    p{
        font-size: var(--p-size);
        line-height: 1.5;
        letter-spacing: -.5px;
        margin-bottom: 8px;
        opacity: .7;
    }
    h3, p, h2, div {
        color: var(--white);
        z-index: 1;
    }
}

.thumbnails_container{
    padding: var(--section-wing);
    @include devices(desktop){
        max-width: var(--desktop-width);
        margin: auto;
        padding: 0;
    }
    &:before{
        content: '';
        width: 100%;
        height: 514px;
        // background-color: var(--white);
        background: linear-gradient(22.51deg, rgba(160, 86, 43, 0) 18.92%, rgba(201, 166, 145, 0.57) 107.05%), #8a5e35;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        // @include devices(mobileandtablet){
        //     height: 234px;
        // }

    }

}

.thumbnails{
    display: grid;

    padding-bottom: var(--section-gap-mobile);

    margin: auto;
    position: relative;
    align-items: center;

    grid-template-areas: 'thumbnail-carousel-button'
                          'thumbnail-carousel';

    @include devices(mobile){
        padding-left: var(--section-wing);
    }

}



@import 'mixin.scss';
@keyframes headerAnimation {
    0% {
        background-position: -200% 0;
    }
    100% {
        background-position: 200% 0;
    }
}
.intro{
        padding:  140px 48px 80px;
        text-align: center;
        background: url('https://images.unsplash.com/photo-1688516353461-540cd4b178fa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2787&q=80');
        background-size: cover;
        h1, p{
            max-width: 658px;
            color: white;
            text-align: left;
        }
        @include devices(desktop){
            // max-width: 658px;
            margin: auto;

        }
        h1 {
            letter-spacing: -3px;
            font-size: 72px;
            position: relative;
            color: var(--white); /* White text color */
            line-height: 1;
        }

        h1::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 100%;
            background-image: linear-gradient(45deg, transparent 45%, rgba(255, 255, 255, 0.5) 45%, rgba(255, 255, 255, 0.5) 55%, transparent 55%);
            background-size: 200% 100%;
            // animation: headerAnimation 3s linear infinite alternate;
            -webkit-background-clip: text;
            background-clip: text;
        }
        p {
            font-size: var(--p-size);
            padding-top: 20px;
        }
}
.container{
    margin-right: var(--section-wing);
    padding-top: var(--section-gap-mobile);
    padding-bottom: var(--section-gap-mobile);
    gap:32px;
    text-align: center;
    display: grid;
    @include devices(desktop){
        // max-width: 738px;
        margin: auto;
        // padding-top: var(--section-gap-desktop);
        grid-template-columns: 1fr 2fr;
    }
    .box{
        border-radius: 8px;
        overflow: hidden;
        background-color: rgba(0,0,0,0.25);
        backdrop-filter: blur(42px);

        color: var(--white);
        h3, h2,p {
            // background-color: var(--primary-10);
        }
        h3 {
            color: var(--white)
        }
        &.highlight{
            background-color: rgba(0,0,0,0.65);
            color: var(--white);
            h3, h2,p {
                // background-color: var(--primary-20);
                color: var(--white);

            }
        }
    }

    h2{
        font-size: var(--small-text);
        line-height: 1;
        letter-spacing:2px;
        text-transform: uppercase;
        padding: 20px 0 0;

        @include devices(tabletanddesktop){
            max-width: 100%;
            margin-left: auto;
            margin-right: auto;
        }


    }

    h3{
        font-size: var(--h2-size);
        opacity: 1;
        margin: 0;
        padding: 8px 16px;
    }
    p{
        font-size: var(--p-text);
        line-height: 1.5;
        font-weight: 500;
        text-transform: none;
        letter-spacing: 0;
        padding: 0 0 20px;
        text-align: center;
        width: 100%;
        margin: 0;
    }
    h4 {
        font-size: var(--small-text);
        letter-spacing: 2px;
        text-transform: uppercase;
        margin: 0;
        opacity: .5;
    }
    ul {
        li{
            // border-bottom: 2px solid;
            // border-color: var(--primary-100-10);
            padding: 12px 0 0;
            font-size: 16px;
            color:var(--white-50);
            letter-spacing: -.5px;
            &:last-of-type{
                border-bottom: none;
            }
        }
        padding-bottom: 28px;
    }

}
.containerHero{
    padding: var(--section-wing);

    padding-bottom: var(--section-gap-mobile);
    text-align: center;
    @include devices(desktop){
        max-width: 738px;
        margin: auto;
        padding-bottom: var(--section-gap-desktop);
    }

    h1{
        font-size: var(--headline-size);
        line-height: 1;
        letter-spacing: -3px;
        margin-bottom: var(--element-gap);
        @include devices(tabletanddesktop){
            max-width: 620px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    h2{
        font-size: var( --p-size);
        opacity: 1;
        margin-bottom: var(--element-gap);
    }

    p{
        font-size: var( --p-size);
        line-height: 1.5;
        font-weight: 500;
        color: var(--white);
    }
}

.hero{
    padding-top: 52px;
    padding-bottom: 100px;
    h2 {
        color: var(--white);
        letter-spacing: 2px;
        text-transform: uppercase;
        margin-bottom: 20px;
    }
}
.cta{
    margin-top: var(--element-gap);
    display: flex;
    justify-content: center;
    
}
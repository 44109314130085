//Breakpoint
@mixin devices ($breakpoint) { //the name of the mixin is devices
  
    @if $breakpoint == desktop {    
        @media only screen and (min-width: 1025px) {
          @content;
        }
      }

    @if $breakpoint == tabletanddesktop {
    @media only screen and (min-width: 482px) {
        @content;
    }
    }
    @if $breakpoint == tablet {    
    @media only screen and (min-width: 482px) and (max-width: 1024px) {
      @content;
    }
  }
 
  @if $breakpoint == mobile {
    @media only screen and (max-width: 481px) {
      @content;
    }
  }

  @if $breakpoint == mobileandtablet {
    @media only screen and (max-width: 1024px) {
      @content;
    }
  }
}

@import 'mixin.scss';

.thumbnail{
    max-width: 340px;
    background-color: var(--white);
    border-radius: 12px;
    margin-right: 1rem;
    box-shadow: var(--shadow-wide);
    cursor: pointer;
    img{
        object-fit: cover;
        width: 100%;
        border-radius: 12px 12px 0 0;

        @include devices(desktop){
            height: 120px;
        }
    }

    &.active_thumbnail {
        max-width: 348px;
        // background-color: var(--primary-100);
        // filter: drop-shadow(0px 25px 48px rgba(0, 0, 0, 0.2));
        max-height: 363px;
        box-shadow: var(--shadow-narrow);
        // h1, p{
        //     color: var(--white);
        // }
        // svg{
        //     fill: var(--white);
        // }
        img{
            height: 180px;
            transition: all .3s;
        }
    
    }


}

.thumbnails_container{
    @include devices(mobileandtablet){
        padding: var(--section-wing);
    }
}

.tiny_thumbnail{
    max-width: 240px;

    .thumbnaiTemplateTileDescription{
        width: 200px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}

.image_thumbnail{
    padding: 3px;
    @include devices(mobileandtablet){
        img{
            height: 120px;
        }
    }


}

.tile_description{
    padding: 12px 20px 20px;
    box-sizing: border-box;
    h2{
        font-size: 16px;
        letter-spacing: -.5px;
        color: var(--navy-1000);
        margin-bottom: 16px;

    }

}

.link_description{
    display: grid;
    grid-template-columns: auto 32px;
    align-items: center;
    p{
        font-size: 14px;
        color: var(--primary-100);
        font-weight: 700;
    }
}



.link_button{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    box-sizing: border-box;
    border: 1px solid var(--primary-100-10);
    border-radius: var(  --small-border-radius);
    background-color: rgba(255, 255, 255, 0.1);
    svg{
        fill: var(--primary-100);
    }
}


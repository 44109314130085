@import 'mixin.scss';

.container{
    padding: var(--section-wing);
    padding-top: var(--section-gap-mobile);
    padding-bottom: var(--section-gap-mobile);
    @include devices(desktop){
        padding-top: var(--section-gap-desktop);
        padding-bottom: var(--section-gap-desktop);
        // max-width:  var(--container-med);
        margin: auto;
    }

    h3{
        font-size: var(--h2-size);
        opacity: .5;
        margin-bottom: 8px;
    }

    h2{
        font-size: 48px;
        letter-spacing: -3px;
        line-height: 1.2;
    }
    
    header{
        margin-bottom: var( --element-gap);
    }
    
}

.faq_item{
    border: 3px solid rgba(239, 236, 231, 1);
    padding: 24px;
    box-sizing: border-box;
    border-radius: 12px;
    margin-bottom: 1rem;
    svg{
        path{
            fill: var(--primary-100);
        }
    }
    h4{
        font-size: 18px;
        display: grid;
        grid-template-columns: auto 35px;
        align-items: center;
        color:var(--grey-100);
        cursor: pointer;
        letter-spacing: -.5px;
        line-height: 28px;
        column-gap: 24px;
        font-weight: 500;
        margin-top: 0;
        margin-bottom: 0;
    }
    p{
        font-size: 16px;
        line-height: 28px;
        opacity: .4;
        margin-top: 1rem;
    }
}
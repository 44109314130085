@import '../mixin.scss';

.templates{
    @include devices(desktop){
        background-color: red;
    }
    @include devices(mobile){
        background-color: yellow;
    }
    
}
@import './css/mixin.scss';


// @font-face {
//     font-family: "Archia";   
//     src: local("Archia"),
//       url("./font/archia-thin-webfont.woff") format("woff");
//     font-weight: 100;
//   }
  
//   @font-face {
//     font-family: "Archia";   
//     src: local("Archia"),
//       url("./font/archia-light-webfont.woff") format("woff");
//     font-weight: 400;
//   }
  
  // @font-face {
  //   font-family: "Archia";   
  //   src: local("Archia"),
  //     url("./font/archia-regular-webfont.woff") format("woff");
  //   font-weight: 500;
  // }
  
  @font-face {
    font-family: "Archia";   
    src: local("Archia"),
      url("./font/archia-medium-webfont.woff") format("woff");
    font-weight: 500;
  }
  
  @font-face {
    font-family: "Archia";   
    src: local("Archia"),
      url("./font/archia-semibold-webfont.woff") format("woff");
    font-weight: 700;
  }
  
  // @font-face {
  //   font-family: "Archia";   
  //   src: local("Archia"),
  //     url("./font/archia-bold-webfont.woff") format("woff");
  //   font-weight: 800;
  // }
  
  body {
    margin: 0;
    font-family:'Archia', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  a{
    text-decoration: none;
  }

  ol,ul,li,h1,h2,p{
      list-style: none;
      padding: 0;
      margin: 0;
  }
  
.slideUp{
  animation: 'slideUp';
  animation-duration: .8s;
}

  @keyframes slideUp {
    0%   {
        margin-top: 100px;
        opacity:0;
    }
    75% {
        opacity:1;
    }
    100% {
        margin-top: 0;
        opacity:1;
    }
}

.hide-on-tablet-mobile{
  display: block;
  @include devices(mobileandtablet){
    display: none;
  }
}

.react-multi-carousel-list  {
  grid-area: thumbnail-carousel;
  // overflow: unset !important;
  // @include devices(mobileandtablet){
  //   padding-left: 32px;
  //   padding-right: 32px;
  // }

}


.react-multi-carousel-track {
  align-items: center;
  height: 332px;
  // max-width: 320px;
  // @include devices(desktop){
  //   max-width: var(--desktop-width);
  // }
}

.carousel-button-group{
  grid-area: thumbnail-carousel-button;
  margin-bottom: 1rem;
  display: grid;
  grid-auto-flow: column;
  cursor: pointer;
  padding: 0 !important;

  svg{
    path{
      fill: #FFF;
    }
  }

  @include devices(mobileandtablet){
    padding: 32px;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.right-arrow{
  display: flex;
  justify-content: flex-end;
}
a.text-link {
  color:var(--grey-70);
  line-height: 1.5em;
  font-weight: 700;
  &:after{
    content:'';
    width:0%;
    height: 3px;
    display: block;
    position: absolute;
    background-color: var(--grey-70);
    position: relative;
    bottom:-8px;
    transition: all .7s;
  }
  &:hover{

    &:after{
      width:20%;
      transition: all .7s;

    }
  }
  &.light {
    color:var(--white);
    &:after{
      background-color: var(--white);
    }
  }
  
}
.faq_block{
  .accordion__button[aria-expanded='true'] {
    svg{
      transform: rotate(180deg);
    }

    h1{
      color: var(--primary-100);
    }
  
  }
}


.article-button-group{
    grid-area: article-button-group;
    margin-bottom: 1rem;
    display: grid;
    grid-auto-flow: column;
    cursor: pointer;
    // padding: var(--section-wing);
    
}

// .article-browser-lists{
//   .react-multi-carousel-track {
//     grid-gap: 16px;
//   }
// }

  
  :root{
    --grey-100: rgba(51, 51, 51, 1);
    --grey-80: rgba(53,51,51,.8);
    --grey-70: rgba(53,51,51,.7);
    --grey-50: rgba(53,51,51,.5);
    --grey-35: rgba(53,51,51,.35);
    --grey-20: rgba(53,51,51,.2);

    --navy-1000: rgba(33,30,34,1);

    --primary-100: rgb(217, 140, 77);
    --primary-20: rgba(217, 140, 77,.75);
    --primary-100-10: rgba(194, 132, 96, .1);
    --primary-30: rgba(226,211,195,1);
    --primary-10: rgba(226,211,195,.3);

    
    --peach-500:rgba(217,174,161,1);

    --beige-100:rgba(247,245,241,1);
    --beige-gradient:linear-gradient(180deg, rgba(247, 245, 241, 0) 0%, #F7F5F1 48.24%);


    --white: rgba(247,245,241,1);
    // --white: rgba(255,255,255,1);
    --white-50: rgba(255,255,255,.5);
  
    --small-text: 14px;
    --regular-text: 1rem;
    --semiregular-text: 18px;
  
    --small-border-radius: 8px;

    --nav-height: 100px;

    /* Section font size */
    --headline-size: 82px;
    --h1-size: 58px;
    --h2-size: 24px;
    --p-size: 16px;
    --small-text: 14px;

    /* GAP */
    --element-gap: 32px;
    --section-gap-desktop: 160px;
    --section-gap-mobile: 40px;

    /*Side page padding*/
    --section-wing: 0 40px;

    /*Page sizes*/
    --desktop-width: 1328px;
    --container-med:880px;

    /*Shadows*/
    --shadow-narrow:rgba(50, 50, 93, 0.25) 0px 10px 20px -20px, rgba(0, 0, 0, 0.75) 0px 16px 20px -30px;
    --shadow-wide:rgba(50, 50, 93, 0.125) 0px 20px 40px -20px, rgba(0, 0, 0, 0.23) 0px 12px 20px -30px;
    }
  
@import 'mixin.scss';

.wrapper{
    display: grid;
    grid-template-areas: 'reco'
                        'blog-post';
    scroll-snap-align: center;


    @include devices(desktop){
        grid-template-areas: 'reco blog-post';
        max-width: 920px;
        margin: 40px auto;
        padding: 0;
        grid-template-columns: 2fr 3fr;
        gap: 32px;
    }

}

.reco{
    grid-area: reco;
    display: grid;
    grid-auto-flow: column;
    overflow: auto;
    align-items: center;
    padding: var(--section-wing);
    box-sizing: border-box;
    // margin: var(--section-gap-mobile) 0;
    scroll-snap-type: x mandatory;
    grid-template-rows:auto max-content;
    align-items: start;

    article{
        scroll-snap-align: start;
        box-shadow: v;
    }
    @include devices(desktop){
        grid-auto-flow: row;
        grid-auto-rows: max-content;
        grid-gap: 16px;
    }
    .quote{
        font-weight: 400;
        letter-spacing: -.5px;
        font-size: 20px;
        line-height: 28px;
        color:var(--grey-70);
    }
    
}

.header{
    margin-bottom: var(--element-gap);
    margin-top: 100px;
    width: 100%;
    max-height: 150vh;
    overflow: hidden;
    padding:0 32px;
    box-sizing: border-box;
    h1,p {
    margin:auto;
    max-width: 680px;
    padding: 52px 0 0;
    letter-spacing: -.5px;
    }

    h1 {
    letter-spacing: -3px;
    font-size: 52px;
    line-height: 1.05;
    }
    p {
        font-size: var(--p-size);
        line-height: 2;
        color: var(--grey-70);
        padding-top: 16px;
        margin-bottom: 52px;
    }
    img{
        width: 100%;
        padding-bottom: 40px;
    }
}
.blog_post{
    grid-area: blog-post;
    padding: var(--section-wing);
    box-sizing: border-box;
    p {
        margin-bottom: 16px;
        line-height: 2em;
    }
    & h1{
        font-size: var(--h1-size);
        letter-spacing: -1px;
        @include devices(mobileandtablet){
            font-size: var(--h1-size);
        }
    }

    h2, p, li,a{
        font-size: var(--p-size);
        line-height: 1.85;
        color: var(--grey-70);
        padding-top: 16px;
        strong {
            color: var(--grey-100);
        }
    }
    h2 {
        color: var(--grey-100);
    }
    h3 {
        color: var(--grey-100);
        font-size: var(--p-size);
        margin:0;
        padding-top: 8px;;
        font-weight: 500;
    }
    a{
        color: var(--primary-100);
        font-weight: bold;
    }
    ol, ul {
        padding-left: 16px;
        padding-bottom: 16px;
    }
    ul {
        li{
            list-style-type: square;
        }
    }
    ol {
        li{
            list-style-type: 1;
        }
    }
    .exampleQuestions{
        background-color: var(--primary-100-10);
        padding: 8px 32px;
        margin-top:8px;
        border-radius: 8px;
        border: 3px solid var(--primary-100-10);
        li {
            color: var(--primary-100);
            padding-top: 0;
            // font-size: var(--small-text);
        }
    }
    li{
        list-style: auto;
        padding-top: 2px;
        // font-weight: 700;
    }


    article{
        margin-bottom: var(--element-gap);
        h1{
            font-size: var(--h2-size);
            // margin-bottom: var(--element-gap);
        }
    }



    @include devices(desktop){
        padding: 0;
    }
}

.lists{
    list-style: auto;
    padding-left: 16px;

}

.lists_bignumbers{
    list-style: auto;
    padding-left: 0;
}
// blog page

.articles{
    max-width: 920px;
    margin:auto;
    display: grid;
    @include devices(desktop){
        grid-template-columns: 1fr 1fr 1fr;
    }
    gap: 20px;
    margin-bottom: 80px;
}
.articles__intro {
    padding: var(--section-wing);
    padding-top:120px;
    text-align: center;
    padding-bottom: var(--section-gap-mobile);
    
    @include devices(desktop){
        // max-width: 738px;
        margin: auto;
    }
    h1 {
        letter-spacing: -1px;
        font-size: 64px;
        line-height: 58px;
    }
    p {
        font-size: var(--p-size);
        padding-top: 20px;
        color:var(--grey-70);
    }
}
.quoter{

    @include devices(mobileandtablet){
        display: block;
        padding: 0 40px;
        border-top: 2px solid;
        border-bottom: 2px solid;
        border-color: var(--primary-30);
    }
}
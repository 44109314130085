@import 'mixin.scss';
.image_report{
    @include devices(desktop){
    height: 400px;
    border-radius: 20px;
    }
}
.content_solution{
    background-color: var(--white);

}
.feature_content{
    display: grid;
    @include devices(desktop){
    grid-template-columns: repeat(3,1fr);
    padding: 40px 80px 80px;
    gap: 32px;}
    padding: 40px 32px 80px;
    background-color: var(--white);
    color:var(--grey-50);
    letter-spacing: -.5px;
    line-height: 1.3;
    h3{
        color:var(--grey-100);
        padding-bottom: 20px;
    }
}
.container{
    padding: 20px 40px;
    // padding-top: var(--section-gap-mobile);
    padding-bottom: var(--section-gap-mobile);
    display: grid;
    text-align: left;
    align-items: start;
    background-color: var(--white);

    @include devices(desktop){
    padding: 40px 80px;
    margin: auto;
        // max-width: var(--desktop-width);
        // max-width: var(--container-med);
        // padding-top: var(--section-gap-desktop);
        padding-bottom: var(--section-gap-desktop);
        // align-items: center;
        justify-items: left;
        grid-template-columns: 4fr max-content;
        display: grid;
        column-gap: 42px;
        grid-template-areas:'feature-highlight-header feature-highlight-image'

    }
    img{
        width: 100%;
    }
    @include devices(mobileandtablet){
        grid-template-areas:'feature-highlight-image'
        'feature-highlight-header' ;
    }

    h2{
        font-size:48px;
        line-height: 1;
        letter-spacing: -3.5px;
        margin-bottom: var(--element-gap);
        color:var(--grey-100);
    }

    h3{
        font-size: var( --p-size);
        opacity: 1;
        margin-bottom: var(--element-gap);
        color:var(--grey-100);
    }

    p{
        font-size: var( --p-size);
        letter-spacing: -.5px;
        line-height: 1.5;
        font-weight: 400;
        color:var(--grey-50);
    }

}
.containerRight{
    @include devices(desktop){
        margin: auto;
        // max-width: var(--desktop-width);
        // max-width: var(--container-med);
        padding-top: 0;
        padding-bottom: var(--section-gap-desktop);
        align-items: center;
        justify-items: center;
        grid-template-columns: 2fr;
        display: grid;
        column-gap: 56px;
        // grid-template-areas:'feature-highlight-image feature-highlight-header'
    }
}

.containerLeft{
    @include devices(desktop){
        grid-template-areas:'feature-highlight-image feature-highlight-header'
    }
}
.header{
    grid-area: feature-highlight-header;
    p {
        padding-bottom: 20px;
    }
}

.images{
    grid-area: feature-highlight-image;
    overflow: hidden;
    border-radius: 16px;
    @include devices(mobileandtablet){
        margin-bottom: 32px;
    }
}
.fullImage {
    width:100%;
    padding:var(--section-wing);
    overflow: hidden;
    box-sizing: border-box;
    img {
        width: var(--desktop-width);
        margin: auto;
        align-items: baseline;
        display: flex;
        max-width: 100%;
    }
}
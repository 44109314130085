@import './mixin.scss';
.page{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
}
.logo{
    position: fixed;
    top:20px;
    left: 0;
    right:0;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--m-spacing) var(--xs-spacing);
}
.wrapper{
    display: grid;
    gap:var(--xs-spacing);
    margin: auto;
    width: max-content;
    max-width: 600px;
    padding: var(--l-spacing) 0;
    color:var(--grey-30);
    @include devices(desktop){
        grid-template-columns: 2fr 4fr;
    }

    
    .content {
        display: grid;
        gap: var(--xxs-spacing);
        padding: 0 var(--s-spacing);
        box-sizing: border-box;
        text-align: center;
        justify-content: center;
        @include devices(desktop){
            text-align: left;
            justify-content: left;
        }
    }
    .image{
        height: 100%;
        display: grid;
        justify-content: center;
        svg{
            height: 100%;
            width: 100%;
        }
    }
    h1{
        color:var(--grey-100);
        letter-spacing: -2px;
        font-size:40px;
        padding: 0;
        margin: 0;
    }
    h2{
        font-size:14px;
        padding: 0;
        margin: 0;
    }
    p{
        font-size: 16px;
        line-height: 1.5em;
        margin-bottom: 8px;
    }

    .button{
        font-family: 'Archia';
        padding: 16px;
        color: white;
        border: none;
        width: max-content;
        border-radius: 12px;
        background-color: black;
        display: block;
        margin: 20px auto;
        @include devices(desktop){
            margin: 20px 0;
        }
        &:disabled{
        background-color: var(--grey-08);
        color:var(--grey-100)
        }
      }
}
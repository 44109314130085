@import 'mixin.scss';



.registerBtn{
  background-color: var(--white);
  color: var(--grey-100);
  font-size: var(--regular-text);
  font-weight: 700;
  line-height: 1.5;
  border-radius: var(--small-border-radius);
  padding: 8px 20px;
  box-sizing: border-box;
  @include devices(mobileandtablet) {
    font-size: var(--small-text);
  }
}

.loginBtn{
  color: var(--white);
  border: 2px solid var(--white);
  font-size: var(--regular-text);
  font-weight: 700;
  line-height: 1.5;
  border-radius: var(--small-border-radius);
  padding: 8px 20px;
  box-sizing: border-box;
  @include devices(mobileandtablet){
    display: none;
    font-size: var(--small-text);
  }
}

.closeButton{
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  border-radius: 100%;
  margin: 16px;
}

.cta{
  background-color: var(--primary-100);
  color: var(--white);
  width: 100%;
  height: 82px;
  border-radius: 8px;
  font-size: 24px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover{
    filter: drop-shadow(0px 18px 52px rgba(0, 0, 0, 0.08));
  }
  @include devices (desktop){
    max-width: 320px;
    filter: drop-shadow(0px 18px 52px rgba(0, 0, 0, 0.08));
  }
}
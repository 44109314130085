.accordion_custom{
    margin-top: var(--element-gap);
    h1{
        font-size: var(--p-size) !important;
        display: grid;
        grid-template-columns: max-content auto;
        align-items: center;
        column-gap: 16px;
        padding-left: 12px;
        border-left: 6px solid;
    }
    p{
        font-weight: 500;
        padding-bottom: 8px;
    }
    li{
        margin-bottom: 16px;
        cursor: pointer;
    }
    div[aria-expanded="true"]{
       .accordion_icon{
           path{
            fill: var(--primary-1000);
           }

       }
       h1{
           color: var(--grey-100);
       }
    }

}

// .accordion_icon{
//     width: max-content;
//     height: 32px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     background-color: var(--white);
//     border-radius: 8px;
//     border: 1px solid rgba(255, 255, 255, 0.35);
//     box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.1);
//     svg {
//         path{
//             fill: var(--grey-100)
//         }
//     }
// }

.accordion__button{
    h1{
        letter-spacing: .2px;
    }
}
@import 'mixin.scss';

.container{
    padding-top: var(--section-gap-mobile);
    padding-bottom: var(--section-gap-mobile);
    display: grid;
    text-align: left;


    @include devices(desktop){
        margin: auto;
        padding-top: var(--section-gap-desktop);
        padding-bottom: var(--section-gap-desktop);
    }

    @include devices(mobileandtablet){
        img{
            width: 100%;
        }

    }

    h2{
        font-size: 48px;
        line-height: 1.3;
        margin-bottom: var(--element-gap);
        letter-spacing: -2.3px;
    }

    h3{
        font-size: var( --p-size);
        opacity: .5;
        margin-bottom: var(--element-gap);
    }

    p{
        font-size: var( --p-size);
        opacity: .35;
        line-height: 1.5;
        font-weight: 700;
    }
    .react-multi-carousel-list {
        padding: 0;
    }
}

.header{
    text-align: left;
    box-sizing: border-box;

    @include devices(desktop){
        padding: 40px;
        max-width: var( --desktop-width);
        margin: auto;
        width: 100%;
    }
    @include devices(mobileandtablet){
        padding: var(--section-wing);
    }
}

.thumbnails{
    display: grid;
    grid-auto-flow: column;
    grid-template-areas: 'article-button-group'
                        'thumbnail-carousel';

    box-sizing: border-box;
    @include devices(desktop){
        max-width: var( --desktop-width);
        margin: auto;
        width: 100%;
    }
    @include devices(mobileandtablet){
        padding: var(--section-wing);
    }

}

.item{
    display: grid;
    border-radius: 12px;
    background-color: white;
    padding: 20px;
    box-sizing: border-box;
    cursor: pointer;
    scroll-snap-align: center;
    box-shadow: var(--shadow-wide);

    h2{
        font-size: var(--p-size);
        margin-bottom: 8px;
        font-weight: 500;
        letter-spacing: -1px;
    }
    p{
        font-size: var(--p-size);
    }

    @include devices(tablet){
            margin-right: 16px;
    }
    @include devices(desktop){
        max-width: 348px;
        min-height: 180px;
        margin-right: 16px;
    }
}

.item_no_height{
    display: grid;
    border-radius: 8px;
    overflow: hidden;
    background-color: white;
    border: 1px solid rgba(0,0,0,0.1);
    box-sizing: border-box;
    scroll-snap-align: center;
    box-shadow: var(--shadow-narrow);
    &:hover{
    // border-color: var(--primary-100);
    }
    h2{
        font-size: 14px;
        line-height: 1.3em;
        font-weight: 500;
        letter-spacing: -.31px;
        margin-bottom: 8px;
        color: var(--grey-100);
    }
    p{
        font-size: var(--p-size);
        opacity: .36;
    }
    a,p{
        font-size: 14px;
    }
    h2{
        padding: 12px 16px 0;
    }
    .tileimage{
        margin: 10px;
        height: 80px;
        overflow: hidden;
        border-radius: 8px;
        background-size: cover;
        img {
            width: 100%;
            margin: auto;
        }
        height: 96px;
        overflow: hidden;
    }
    @include devices(tablet){
    }
    @include devices(desktop){
        max-width: 348px;
        // min-height: 277px;
        
    }
}

.item_links{
    display: grid;
    grid-template-rows: repeat(auto-fill, minmax(4px, 1fr));
    grid-gap: 1em;
    align-items: flex-end;
    padding: 0 16px 16px 16px;
    a{
        color: var(--primary-100);
        text-decoration: underline;
    }
}

.item_inactive{
    display: grid;
    grid-template-columns: auto 32px;
}

.arrow{
    display: flex;
    border: 2px solid var(--primary-100-10);
    justify-content: center;
    border-radius: 8px;
    svg{
        path{
            fill: var(--primary-100);
        }
    }
}

.item_link{
    color: var(--primary-100);
    font-size: 14px;
}

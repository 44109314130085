@import 'mixin.scss';
.intro, .content{
        padding: var(--section-wing);
        padding-top: 120px;
        text-align: left;
        @include devices(desktop){
            max-width:  var(--container-med);
            margin: auto;
        }
        h1 {
            letter-spacing: -1px;
            font-size: var(--h1-size);
        }
        h2, h3{
            padding: 16px 0;
            margin: 0;
            padding: 20px 0;

        }
        p {
            font-size: var(--p-size);
            padding-top: 0;
            color:var(--grey-70);
            line-height: 1.5em;
            padding-bottom: 20px;
        }
}
.intro{
    i {
        padding-top: 16px;
        display: block;
        color:var(--grey-50);
    }
}

.content {
    // margin-bottom: var(--section-gap-mobile);
    padding:0 32px;
    margin: 40px auto;
    ol {
        padding: 20px 32px;
    }
    li {
        list-style-type:decimal;
    }

}

.container{
    padding: var(--section-wing);
    padding-top: var(--section-gap-mobile);
    padding-bottom: var(--section-gap-mobile);
    gap:32px;
    text-align: center;
    display: grid;
    @include devices(desktop){
        max-width: 738px;
        margin: auto;
        // padding-top: var(--section-gap-desktop);
        grid-template-columns: 1fr 2fr;
    }
    .box{
        border-radius: 8px;
        overflow: hidden;
        background-color: var(--primary-10);
        color: var(--grey-70);
        h1, h2,p {
            background-color: var(--primary-10);
        }
        h2 {
            color: var(--grey-100)
        }
        &.highlight{
            background-color: var(--primary-20);
            color: var(--white);
            h1, h2,p {
                background-color: var(--primary-20);
                color: var(--white);

            }
        }
    }

    h1{
        font-size: var(--small-text);
        line-height: 1;
        letter-spacing:2px;
        text-transform: uppercase;
        padding: 20px 0 0;

        @include devices(tabletanddesktop){
            max-width: 680px;
            margin-left: auto;
            margin-right: auto;
        }


    }

    h2{
        font-size: var(--h2-size);
        opacity: 1;
        margin: 0;
        padding: 8px 16px;
    }
    p{
        font-size: var(--p-text);
        line-height: 1.5;
        font-weight: 500;
        text-transform: none;
        letter-spacing: 0;
        padding: 0 0 20px;
    }
    h3 {
        font-size: var(--small-text);
        letter-spacing: 2px;
        text-transform: uppercase;
        opacity: .5;
    }
    ul {
        li{
            // border-bottom: 2px solid;
            // border-color: var(--primary-100-10);
            padding: 16px 0 0;
            font-size: var(--p-text);
            letter-spacing: -.5px;
            &:last-of-type{
                border-bottom: none;
            }
        }
        padding-bottom: 28px;
    }

}
.containerHero{
    padding: var(--section-wing);
    padding-top: var(--section-gap-mobile);
    padding-bottom: var(--section-gap-mobile);
    text-align: center;
    @include devices(desktop){
        max-width: 738px;
        margin: auto;
        padding-bottom: var(--section-gap-desktop);
    }

    h1{
        font-size: var(--headline-size);
        line-height: 1;
        letter-spacing: -3px;
        margin-bottom: var(--element-gap);
        @include devices(tabletanddesktop){
            max-width: 620px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    h2{
        font-size: var( --p-size);
        opacity: 1;
        margin-bottom: var(--element-gap);
    }

    p{
        font-size: var( --p-size);
        line-height: 1.5;
        font-weight: 500;
        color: var(--grey-50);
    }
}

.hero{
    padding-top: 52px;
    padding-bottom: 100px;
    h2 {
        color: var(--grey-50);
        letter-spacing: 2px;
        text-transform: uppercase;
        margin-bottom: 20px;
    }
}
.cta{
    margin-top: var(--element-gap);
    display: flex;
    justify-content: center;
    
}
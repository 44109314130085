@import 'mixin.scss';
.app {
  background-color: var(--white);
  width: 100vw;
}
.letter {
  opacity: 0;
}
.animate {
  animation: slideUp 0.5s ease-in-out forwards;
  opacity: 1;
}

@keyframes animateWord {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animateWord {
  display: inline-block;
  opacity: 0;
  animation: animateWord 0.25s ease-in-out forwards;
}

/* Keyframes for sliding up animation */
@keyframes slideUp {
  0% {
    display: inline-block;
    opacity: 0;
    transform: translateY(60px) scale(0.8);
  }
  100% {
    display: inline-block;
    transform: translateY(0) scale(1);
    opacity: 1;
  }
}

/* Apply the animation to each word */
.animateWord {
  display: inline-block;
  // overflow: hidden;
  height: 232px;
  &.twolines {
    @include devices(mobile){
      height: 360px;

    }
  }
}
.parallaxPanel {
  height: 100vh; /* Set the panel height to the viewport height */
  // display: flex;
  align-items: top;
  justify-content: center;
  // background-color: #242638;
  // background: linear-gradient(to top, #bd7034, #e29458);    position: relative;
  // background:url('https://images.unsplash.com/photo-1688141584989-750cfd31fd61?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2787&q=80');
  // background:url('https://images.unsplash.com/photo-1690046793146-dd1cc08ea17d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80');
  // background:url('https://images.unsplash.com/photo-1689871176587-29efb3975d5c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2842&q=80');
  // background:url('https://images.unsplash.com/photo-1676902683876-527e9816ae4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2835&q=80');
  // background-position: 0 70%;
  background: url("https://images.unsplash.com/photo-1676902684032-a2e4436d553f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2835&q=80");
  background-size: cover;
  background-position: 0 38%;
  overflow: hidden;
  transition: transform 0.3s ease; /* Smoothly transition the transform property */
  @include devices(desktop){
    align-items: top;
    justify-content: center;
    }
}

.heroText {
  color: #ffffff; /* Set the text color */
  // position: relative;
  z-index: 1; /* Place the text above the image */
  letter-spacing: -8px;
  // width: 100vw;
  font-size: 80px;
  line-height: 80px;
  left: 20px;
  top: 80px;
  @include devices(desktop){
  font-size: 162px;
  line-height: 142px;
  padding: 140px 80px 0;
  left: 0;
  top:  0;
  }
  text-align: left;
  position: absolute;
  box-sizing: border-box;
  display: block;
  box-sizing: border-box;
  // opacity: 0;

  &.final {
    // opacity: 1;
  }
}

.heroTextH2 {
  font-size: 32px;
  display: block;
  text-align: center;
  left: 0;
  width: 100vw;
  right: 0;
  color: var(--white);
  position: absolute;
  padding: 80px 80px;
  box-sizing: border-box;
}
.textInfo {
  color: rgba(255, 255, 255, 0.5);
  position: absolute;
  bottom: 72px;
  right: 20px;
  left: 20px;
  line-height: 1.45;
  max-width: 100%;
  font-size: 16px;
  @include devices(desktop){
    bottom: 72px;
    max-width: 35%;
    right: 20px;
    left: unset;
  }
  h3 {
    color: var(--white);
    font-size: 20px;
    font-weight: 100;
    margin: 0 0 8px;
  }
  .buttons {
    display: flex;
    gap: 12px;
    padding-top: 20px;
    button {
      font-weight: bold;
      font-size: 16px;
      cursor: pointer;
      padding: 16px;
      font-family: Archia;
      border-radius: 8px;
      border: 0;
    }
  }
}

.heroImage {
  max-width: 100%;
  height: auto;
  position: absolute;
  bottom: -280px; /* Adjust the position as needed */
  left: 0;
  display: flex;
  align-items: baseline;
  right: 0;
  iframe {
    transform: scale(0.7);
    border: 4px solid black;
    border-radius: 12px;
    width: 100%;
  }
}

.screenWidePanel {
  min-height: 100vh; /* Set the panel height to the viewport height */
  display: flex;
  align-items: center;
  justify-content: center;
  // background-color: #ffffff; /* Set the background color of the panel */
  font-size: 24px; /* Adjust the font size */
  &.top{
    align-items: start;

    box-sizing: border-box;
  }
  &.white{
    background-color: white;
  }
}
.scrollLine {
  background-color: var(--grey-20);
  margin: auto;
  position: relative;
  display: flex;
  align-items: start; /* Center vertically */
  justify-content: center;
  @include devices(desktop){
    align-items: center; /* Center vertically */
  }
  h2 {
    margin: auto;
    top: 10%; /* Move the top of h2 to the middle of the parent */
    left: 10%; /* Move the left of h2 to the middle of the parent */
    position: absolute;
    padding-left: 40px;
    text-align: left;


      @include devices(mobile){
        margin: 0; /* Remove any default margin */
        text-align: center;
        padding-left: 0; /* Remove left padding on mobile */
        margin-top: 20px; /* Adjust as needed */
        top: 10%;
        background-color: var(--white);
        transform: translateX(-50%) !important; /* Adjust vertical position */
        overflow: hidden;
      }

  }
}
.cardTitle {
  // position: absolute;
  width: 100vw;
  // left: 0;
  // top: 0;
  // padding-left: 40px;
  // font-size: 40px !important;
}
.screenWidePanel_content__fourcol {
  width: 100vw;
  position: relative;
  @include devices(desktop){
  display: flex;

  }
  .panel {
    width: 100%;
    @include devices(mobile){
      margin-top: 56px !important;
    }
    height: 480px;
    // margin-top: 280px;
    color: white;
    background-color: black;
    display: grid;
    grid-template-rows: auto max-content;
    padding: 24px 24px 48px;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
    .panel_image{
      position: absolute;
      z-index: 1;
      left: 0;
      width: 100%;
      // right: 0;
      top:0;
      // transform: translateX(-250px);
    }
    h3 {
      text-align: right;
      margin: 0;
      font-size: 48px;
      z-index: 3;

    }
    p {
      font-size: 16px;
      z-index: 3;
      font-weight: bold;
      span {
        display: block;
        padding-top: 8px;
        font-weight: normal;
      }
    }
  }
}
.screenWidePanel_content__twocol {
  display: grid;
  grid-template-rows: 3fr 2fr;
  
  @include devices(desktop){
    grid-template-columns: 3fr 2fr;
    grid-template-rows: unset;
  }
  .screenWidePanel_content__twocol_text {
    @include devices(desktop){
      height: 100%;
      padding: 120px 64px 64px;
    }
    box-sizing: border-box;
    display: grid;
    grid-template-rows: auto max-content;
    font-size: 16px;
    line-height: 24px;
    // background-color: #353748;
    // color: #fff;
    color: var(--grey-80);
    h2 {
      line-height: 48px;
      color: var(--grey-100);
      font-size: 48px;
      letter-spacing: -2px;
    }
    @include devices(mobile){
      h2, p {
        padding: 20px;
        box-sizing: border-box;
      }
    }
  }
  .screenWidePanel_content__twocol_image {
    background-color: #d99961;
    display: flex;
    align-items: center;
    justify-content: center;
      position: relative;
      @include devices(desktop){
        height: 100vh;

      }
    img {
      border-radius: 20px;
      overflow: hidden;
      position: absolute;
      width: 75%;
    }
  }
}
.screenWidePanel_content__small {
  padding: 88px 20px 0;
  max-width: 650px;
  margin: auto;
  text-align: center;
  display: grid;
  place-items: center;
  @include devices(mobile){
    padding:88px 20px 0 !important;
  }
  p {
    display: block;
    padding: 20px 0;
    color: var(--grey-50);
    font-weight: normal;
    font-size: 16px;
    letter-spacing: -0.5px;
    line-height: 28px;
  }
  h2 {
    letter-spacing: -4px;
    font-size: 48px;
    line-height: 48px;

    @include devices(desktop){
      font-size: 52px;
      line-height: 56px;
    
    }
  }
}
.hide {
  animation: hideWord 0.25s ease-in-out forwards;
}

@keyframes hideWord {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

// nav

.mainNavigation {
  // font-size: 14px;
  color: white;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  width: 100vw;
  position: fixed;
  z-index: 99;
  padding: 16px 24px;
  box-sizing: border-box;
  &.scrolled {
    background-color: var(--grey-20);
    backdrop-filter: blur(20px);
    transition: all 0.2s;
  }
  ul {
    display: flex;
    li {
      padding: 12px;
      a,
      a:link,
      a:active,
      a:visited {
        color: white;
      }
    }
  }
  .mainLogo {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;

    path {
      fill: white;
    }
  }
  .mainNavigation_actions {
    justify-content: end;
    .cta {
      padding: 12px;
      background-color: rgba(255, 255, 255, 0.05);
      border-radius: 8px;
      border: 1px solid rgba(255, 255, 255, 0.25);
    }
  }
}
.dockedPanel{
  position: sticky;
  // bottom: 2400px;
  z-index: 9; /* Ensure it's above other content */
  width: 100%;
  height: 100vh;

  // transform: translateY(8400px);
}
.screenPanel__types_content{
  width: 100vw;
  box-sizing: border-box;
  padding: 40px;
  .table__header{
    font-size: 12px;
    text-transform: uppercase;
    border-bottom: 1px solid var(--grey-20);
    color: var(--grey-50);
    padding-bottom: 8px;
    margin-bottom: 12px;
    padding-top: 28px;
  }
  .title{
    padding-bottom: 56px;
    h2{
      letter-spacing: -3px;
      max-width: 428px;
      line-height: 52px;
      font-size: 46px;
      @include devices(desktop){
        line-height: 56px;
        font-size: 52px;
      }
    }
    p{
      font-size: 16px;
      padding: 20px 0;
      color: var(--grey-50);
    }
  }
  .table{
    display: grid;
    // grid-template-columns: repeat(2,1fr);
    @include devices(desktop){
      grid-template-columns: repeat(4,1fr);
    }
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -.5px;
    color: var(--grey-100);
    padding: 0 0 28px;
    align-items: end;
    gap: 32px;
    a, a:active, a:visited{
      color:var(--primary-100);
      font-weight: bold;
    }
    h3{
      letter-spacing: -1px;
      margin: 0 0 12px;
      line-height: 1.1;
      font-size: 24px;
    }
    .types_image{
      width:100%;
      border-radius: 8px;
      border: 3px solid black;
      border-top: 12px solid black;
    }
    .tableCol{

    }
    
  }
}
.freemiumDetails{
  display: grid;

  @include devices(desktop){
    grid-template-columns: 2fr 2fr;
    column-gap: 40px;
  }
    li{
      display: grid;
      grid-template-columns: 1fr 4fr;
      text-align: left;
      align-items: end;
      color: var(--grey-50);
      border-top: 1px solid var(--grey-20);
      padding:20px 0  40px;
      box-sizing: border-box;
      max-width: 520px;
      h4, p, strong{
        line-height: 1;
        margin: 0;
        padding: 0;
        font-size: 16px;
        display: block;
        font-weight: normal;
      }
      p{
        width: 100%;
      }
      img{
        width: 100%;
        padding:0 20px;
        box-sizing: border-box;
      }
      strong{
        font-size: 22px;
        letter-spacing: -1px;
        color:var(--grey-100);
        display: block;
        padding-bottom: 12px;
      }
    }


}
.primaryCta{
  display: flex;
  width: max-content;
  margin: auto;
  padding: 20px;
  border-radius: 12px;
  font-size: 16px;
  background-color: var(--grey-100);
  color:var(--white);
  cursor: pointer;
}
.primaryCta_helper{
  font-size: 14px;
  display: flex;
  text-align: center;
  margin: auto;
  justify-content: center;
  padding: 32px 0;
  color: var(--grey-50);
}
.footer{
  display: block;

  h2{
    letter-spacing: -3px;
    font-size: 52px;
    max-width: 428px;
    line-height: 56px;
    padding:20px 40px 80px;
    box-sizing: border-box;
  }
  .medium_articles{
    display: grid;
    gap: 20px;
    
    padding: 40px;
    box-sizing: border-box;
    @include devices(desktop){
      width: 100%;
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .medium_article{
      // width:33%;
      a, a:visited, a:active{
        color:var(--grey-80);
        background-color: white;
        // background-color: var(--primary-10);
        border: 2px solid var(--primary-10);
        padding:20px;
        box-sizing: border-box;
        border-radius: 8px;
        height: 100%;
        grid-template-rows: min-content 2fr auto ;
        gap: 20px;
        display: grid;
        // border: 2px solid transparent;
        transition: all .25s;
        &:hover{
          border-color: var(--grey-20);
          transition: all .25s;
          color: var(--primary-100);
        }
      }
      span{
        font-size: 12px;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: bold;
        user-select: none;
        color: var(--grey-50);
      }
      h3{

        font-weight: normal;
        font-size: 16px;
        letter-spacing: -.5px;
        line-height: 22px;
        margin: 0;

      }
      .img{
        width:100%;
        overflow: hidden;
        height: 80px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          width: 100%;
          object-fit: contain;
        }
      }
    }
  }
}
.mainfooter{
  background-color: black;
  .banner_container{
    display: grid;
    align-items: start;
    @include devices(desktop){
      grid-template-columns: 3fr 2fr;
    }
    padding: 0 40px;
    color: var(--white);
    header{
      display: grid;
      gap: 20px;
      padding: 56px 0 20px;
      height: 100%;
    }
    h2{
      padding: 0;
    }
    p{
      font-size: 16px;
    }
    img{
      width: 80%;
      @include devices(desktop){
        padding-top: 220px;
      }
    }
  }
}
.footer__nav_container{
  color: var(--white-50);
  font-size: 16px;
  line-height: 32px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @include devices(desktop){
    grid-template-columns: repeat(4, 1fr);

  }
  padding: 40px;
  a{
    color: white;
    font-weight: bold;
  }
}

.content2{
  background: #9e4f32;
  width: 100%;
}
.hideOnMobile{
  @include devices(mobile){
    display: none !important;
  }
}
@import 'mixin.scss';

.banner_container{
    background-color: rgba(226, 211, 195, 1);
    @include devices(desktop){
        display: flex;
    }
}

.banner{
    padding: var(--section-wing);
    padding-top: var(--section-gap-mobile);
    padding-bottom: var(--section-gap-mobile);
    @include devices(desktop){
        // padding-top: var(--section-gap-desktop);
        // padding-bottom: var(--section-gap-desktop);
        margin: auto;
        max-height: 516px;
    }

    h2, p{
        margin-bottom: 1rem;
        line-height: 1.2;
    }
    h2{
        font-size: 40px;
        line-height: 40px;
        letter-spacing: -2.5px;
    }
    p{
        opacity: .5;
    }
}

.banner_img{
    @include devices(mobile){
        display: none;
    }
    img {
        @include devices(desktop){
            height: 516px;
            vertical-align: bottom;
        }
    }
}
.nav_container{
    display: grid;
    padding: var(--section-wing);
    @include devices(mobileandtablet){
        grid-template-columns: 1fr 1fr;
        padding-top: var(--section-gap-mobile);
        column-gap: 1rem;

    }
    @include devices(desktop){
        grid-auto-flow: column;
        max-width: var(--desktop-width);
        margin: auto;
        padding-top: 60px;
        // padding-bottom: var(--section-gap-desktop);
    }
    a{
        color: var( --grey-50);
    }

    ul{
        margin-bottom: 20px;
    }

    li{
        margin-bottom: 1rem;
    }
}

.logo{
    display: block;
    @include devices(mobile){
    display: none;

    }
    img {
        max-width: 189px;
    }
}

.logo_mobile{
    display: none;
    @include devices(mobile){
        display: block;
        padding: var(--section-wing);
        padding-bottom: var(--section-gap-mobile);
        img{
        height: 20px;
        }
    }
}

.header{
    font-size: var(--p-size);
    margin-bottom: 20px;
    font-weight: 700;
}

.templates{
    @include devices(desktop){
    display: grid;
    grid-template-columns: 1fr 1fr;
    }
}

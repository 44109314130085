@import 'mixin.scss';

.mainHeader{
   padding: 20px;
   box-sizing: border-box;
   display: grid;
   align-items: center;
   grid-template-columns: max-content 1fr;
   width: 100vw;
   position: fixed;    
   top:0;
   left: 0;
   z-index: 2;
   //backdrop-filter: blur(8px);
   @include devices (desktop){
       grid-template-columns: 1fr auto 1fr;
        // position: fixed;
        width: 100%;

        margin:auto;
        z-index: 2;
        padding: 20px;
        box-sizing: border-box;
        margin-bottom: 12px;
   }
   &.dark{
    color: var(--grey-100) !important;


        li {
            color: var(--grey-100) !important;
            }


   }
   
}

.mainHeaderContainerSticky{
    width: 100vw;
    top: 0;
    z-index: 2;
   z-index: 9999;
    background-color: var(--grey-35);
    // box-shadow: var(--shadow-wide);
    &.scrolled {
        transition: all 0.2s;
        position: fixed;
        .mainHeader{
        background-color: var(--grey-35);
        backdrop-filter: blur(20px);
        }
      }
}

.mainLogo{
    display: flex;
    column-gap: 8px;
    align-items: baseline;
    justify-content: center;
    animation-duration: 3s;
    img{
        width: 180px;
      }
}


.rightSideNav{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 12px;
}

.hamburgerIcon{
    @include devices(desktop){
        display: none;
    }
}

.mainNavigation{
    ul{
        list-style: none;
        display: flex;
        justify-content: left;
        // column-gap: var(--element-gap);
    }
    li{
        font-size: var(--semiregular-text);
        letter-spacing: -.5px;
        color: var(--white);
        font-weight: 700;
        padding: 8px 12px 6px;
        // border-top: 5px solid transparent;
        box-sizing: border-box;
        cursor: pointer;
        &.active, &:hover, &:focus{
            // background-color: var(--grey-100);
            // color: var(--white);
            // border-top: 5px solid;
            border-color:var(--primary-100);
            border-radius: 0;
            transition: border-bottom .25s ease-in-out;
            color: var(--primary-100);

            a{
                color: var(--primary-100);
            }
        }
        a{
            color: var(--white);
        }
    }
    @include devices(mobileandtablet){
        display: none;
    }    
}


@import 'mixin.scss';
.title{

}
/* CSS for fish animation */
@keyframes fishAnimation {
    0% {
        top: -22px; /* Maintain the position from the top */
        right: 20px; /* Move to the center horizontally */
        // transform: rotate(-12deg) scaleX(1);
        // transform: scaleX(1);
        transform: rotate(12deg) scaleX(.91);
        // transform-origin: top left;
    }
    45% {
        top: -16px; /* Maintain the position from the top */
        right: 16px; /* Move to the center horizontally */
        // transform: rotate(80deg) scaleX(1);
        // transform: scaleX(1);
        transform: rotate(-12deg) scaleX(1);

        // transform-origin: top left;
    }
    48% {
        top: -16px; /* Maintain the position from the top */
        right: 22px; /* Move to the center horizontally */
        // transform: rotate(80deg) scaleX(.7);
        transform: rotate(1deg) scaleX(.92);
        // transform-origin: top left;
    }
    55% {
        top: -12px; /* Maintain the position from the top */
        right: 22px; /* Move to the center horizontally */
        transform: rotate(-11deg) scaleX(1);
        // transform: scaleX(1);
        // transform-origin: top left;

    }
    100% {
        top: 0; /* Maintain the position from the top */
        right: 22px; /* Move to the center horizontally */
        // transform: rotate(80deg);
        transform: scaleX(1);
        // transform-origin: top left;
    }
  }
  
  /* CSS for paw animation */
  @keyframes pawAnimation {
    0%, 100% {
        bottom: -45px; /* Starting and ending position from the top */
        right: -30px; /* Starting position off-screen to the left */
        transform: rotate(80deg);
    }
    25% {
      bottom: -45px; /* Maintain the position from the top */
      right: -22px; /* Move to the center horizontally */
      transform: rotate(80deg);
    }
    50% {
      bottom: -12px; /* Move back up to the top */
      right: -22px; /* Maintain the position horizontally */
      transform: rotate(80deg);
    }
    85% {
      bottom: -20px; /* Move back up to the top */
      right: -22px; /* Maintain the position horizontally */
      transform: rotate(80deg);
    }
  }
  
  
  
  /* Apply animations to images */
  .fish2 {
    position: absolute;
    right:-20px;
    animation: fishAnimation 2s ease-in-out infinite;

  }
  
  .paw {
    position: absolute;
    right:-40px;
    animation: pawAnimation 2s ease-in-out infinite;
  }
  
.fish1{
    position: absolute;
    z-index: 0;
    top: -20px;
    right:0;
    animation: appear .5s;
}
.fish3{
    position: absolute;
    top:0;
    left: 0;
    animation: appear2 2.5s infinite;
}
.cat6{
    position: absolute;
    z-index: 0;
    bottom: 0;
    right:0;
    animation: appearAdoptionGoal .5s alternate;
}
.happycat{
    position: absolute;
    z-index: 0;
    animation: happy .5s;
    bottom: -20px;    
    right: 10px;
}
@keyframes happy {
    0%{right:10px; bottom: -204px; transform:rotate(0deg) scale(1);}
    80%{right:10px; bottom: -24px; transform:rotate(0deg) scale(1);}
    100%{right:10px; bottom: -24px; transform:rotate(0deg) scale(1);}
}
.nerve{
    position: absolute;
    z-index: 0;
    bottom: 24px;
    right:4px;
    animation: nerve 4s infinite alternate;
}
@keyframes nerve {
    0%{transform:rotate(0deg) scale(.8);}
    50%{transform:rotate(-10deg) scale(1);}
    85%{transform:rotate(10deg) scale(1.1);}
    100%{transform:rotate(0deg) scale(.8);}
}
.cat {
    display: flex;
    align-items: center;        
    position:absolute;
    width: 136.87px;
    height: 211px;
    right: 32px;
    bottom: -12px;
    img {
        width: 136.87px;
        height: 211px;
        position:absolute;
        &.cat2, &.cat5{
            animation: blink 2s infinite alternate;
        }
    }
}
.movecat{
    transition: all .5s;
    transform: translateY(200px);
}

.cat img {
    // transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    transition: all 1s;

  }
  
  .cat img.slide-out {
    opacity: 0;
    transform: translateY(100px);
  }
@keyframes blink {
    0% {
        opacity: 0;
    }
    90% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes expand {
    0%{width:16px;opacity:0}
    20%{width:16px;opacity:1}
    100%{width:120px;opacity:1}
}
@keyframes appear {
    0%{top:-200px;}
    100%{top:-20px;}
}
@keyframes appear2 {
    0%{top:-200px;opacity:1}
    20%{top:-20px;opacity:1}
    80%{top:-20px;opacity:1}
    95%{top:200px;opacity:1}
    100%{top:400px;opacity:0}
}
@keyframes appearAdoptionGoal {
    0%{bottom:-200px;}
    100%{bottom:0;}
}
@keyframes cut {
    0%{opacity:1;}
    80%{opacity:1;}
    81%{opacity:0;}
    100%{opacity:0;}
}
@keyframes cut2 {
    0%{opacity:0;}
    80%{opacity:0;}
    81%{opacity:1;}
    100%{opacity:1;}
}
@keyframes scissors {
    0%{opacity:0; left: -86px;}
    30%{opacity:1; left: 0;}
    80%{opacity:1; left: 72px;}
    100%{opacity:0; left: 126px;}
}
@keyframes friend1appear{
    0%{bottom: -86px;}
    10%{bottom: -65px;}
}
@keyframes friend2appear{
    0%{bottom: -86px;}
    30%{bottom: -86px;}
    35%{bottom: 0;}
}
.cat7{
    position: absolute;
    bottom: -65px;
    left: 40px;
    width: 88px;
    animation: friend1appear 2.5s;
}
.catfriend{
    position: absolute;
    bottom: 0;
    right: 40px;
    animation: friend2appear 2.5s;
}
.scissors{
    position: absolute;
    top: 8px;
    left: 52px;
    transform: rotate(90deg);
    animation: scissors 2.5s infinite;
}
.scissors1{
    position: absolute;
    animation: cut 2.5s infinite;
    top: 0;
}
.scissors2{
    animation: cut2 2.5s infinite;
    position: absolute;
    top: 0;
}
.active{
    background-color: #894813 !important;
    transition: all .5s;
}

.scrollfade_text {
    opacity: 0; /* Initially hidden */
    transform: translateY(40px); /* Move it down initially */
    transition: opacity 0.5s, transform 0.5s;
    content:'';
    // padding-right: 32px;
    // position: absolute;
    // bottom: 28px;
    // transition: all .5s;

    &.show {
        opacity: 1; /* Show the text */
        transition: all .5s;
        transform: translateY(0); /* Move it up */
        // animation-name: expand;
        // animation-duration: 2s;

      }
  }
.search{
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;
    border-bottom: 1px solid var(--grey-20);
    border-top: 1px solid var(--grey-20);
    display: grid;
    grid-template-columns: auto max-content;
    gap: 20px;
    input{
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
        background: rgba(0,0,0,0.01);
        border:2px solid rgba(0,0,0,0.1);
        border-radius: 12px;
        font-size: 16px;
        font-family: 'Archia';
    }
    .filter{
        display: grid;
        grid-template-columns: auto max-content;
        font-family: 'Archia';
        gap: 20px;
        align-items: center;
        font-size: 16px;
        select{
            width: 80px;
            padding: 16px 12px;
        font-size: 16px;
        border-radius: 12px;
            height: 60px;
            border: 2px solid var(--grey-20);
        }
    }
}
.cards{
    padding: 48px 32px 88px;
    position: relative;
    box-sizing: border-box;
    width: 100vw;
    max-width: 1200px;
        display: grid;
        margin: auto;
    gap: 32px;
    @include devices(desktop){
        display: flex;
    }
}
.onecol{
    background-color: var(--beige-100);
    width: 100%;
    height: 320px;
}
.twocol{
    border-radius: 20px;
    width: 100%;

    @include devices(desktop){
        width: 50%;
    }
}
.card{
    // background-color: #bb6925;
    background-image: linear-gradient(90deg,#ff8827, #e08337);
    width: 100%;
    height: 400px;
    box-sizing: border-box;
    padding: 32px;
    border-radius: 20px;
    position:relative;
    overflow: hidden;
    h3{
        margin: 0;
        line-height: 36px;
        font-size: 32px;
        color: white;
        letter-spacing: -1px;
        font-weight: 100;
    }
    h2{
        color: white;
        font-size: 14px;
        padding-bottom: 12px;
        text-transform: uppercase;

    }
    p{
        line-height: 24px;
        color: white;
        opacity: 0.5;
    }
}
.cardsmall{
    background-color: #1f1e1e;
    padding: 20px;
    box-sizing: border-box;
    height: 184px;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    color:white;
    h3{
        margin: 0;
        z-index: 22;
        font-size: 24px;
        line-height: 24px;
    }
    h4{
        color: #1f1e1e;
        background-color: white;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        margin: 0 0 8px;
    }
    p{
        margin-top: 12px;
        opacity: 0.8;
        z-index: 22;
        font-size: 14px;
        width: 100%;
        font-weight: 500;
        box-sizing: border-box;
    }
}
.fourcol{
    border-radius: 20px;
    gap: 20px;  
    padding-top: 20px;
    display: grid;
    @include devices(desktop){
        width: 50%;
        gap: 32px;  
    padding-top: 0;
    height: 320px;
        grid-template-columns: repeat(2,1fr);
    }

}
.blurb{
    width: 100vw;
    max-width: 1200px;
    margin: auto;
    box-sizing: border-box;
    padding: 32px 32px 0;
    text-align: left;
    opacity: .5;
}
.header{
    display: grid;
    grid-template-columns: 1fr max-content;
    gap: 32px;
    padding: 48px 32px 0;
    box-sizing: border-box;
    justify-content: left;
    width: 100vw;
    max-width: 1200px;
    margin: auto;
    text-align: center;
    align-items: center;
    position: relative;
    strong{
        display: block;
        width: max-content;
        padding: 12px;
        font-size: 180px;
        z-index: 0;
        letter-spacing: -12px;
        position: absolute;
        top: -88px;
        right: 0;
        left: 0;
        margin: auto;
        // transform: rotate(-8deg);
        // background-color:#413B39;
        // color:  var(--beige-100);
    }
    a{
        color:var(--primary-100);
        font-weight: bold;
    }
    @include devices(desktop){
    }
    h1{
        letter-spacing: -1.5px;
        font-size: 36px;
        font-weight: 800;
        line-height: 42px;
        color:#413B39;
        padding: 0;
        text-align: left;
    }
    p{
        color:var(--grey-70);
        padding-bottom:56px;
        display: block;
        line-height: 24px;
        max-width: 720px;
        margin: auto;
    }
}
.question{
    // padding-top:16px;
    // background-color: white;
    // border: 1px solid rgb(239, 239, 239);
    position: relative;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: rgb(231, 231, 231) 0px 0px 0px 1.5px;
    h3, h2{
    cursor: pointer;

    }
    &:hover{
        .action{
            img {
                opacity: 1;
            }
        }
        // &::after{
        //     content: 'Click to copy';
        //     transition: all .3s;
        //     background-color: #000000e5;
        //     display: block;
        //     width: max-content;
        //     height: max-content;
        //     border-radius: 8px;
        //     color: white;
        //     position: absolute;
        //     padding:8px;
        //     right: 8px;
        //     top: -32px;
        //     op
        //     margin: auto;
        //     font-size: 14px;
        // }
        transition: all .5s;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    }
    .control{
        display: grid;
        cursor: pointer;
        grid-template-columns: max-content max-content;
        gap: 4px;
        padding: 20px 28px 0;
    }
    .action{
            position: absolute;
            font-style: normal;
            padding:2px 6px;
            right:-4px;
            top: 0;
            width: 52px;
            // background: #000;
            color: #fff;
            border-radius: 8px;
            text-transform: none;
            letter-spacing: 0;
        img {
            width: 16px;
            padding: 16px 20px;
            opacity: .2;
        }

    }
    h2{
        font-size: 16px;
        line-height: 22px;
        font-weight: 200;
        letter-spacing:-.5px;
        // background-color: black;
        // color:white;
        padding: 0 28px 32px;
        &.Casual{
        }
        &.Formal{

        }
    }
    h3{
        padding: 0;
        padding-bottom: 8px;
        font-size: 14px;
        margin: 0;
        display: grid;
        // padding: 20px 0;
            opacity: .3;
            align-items: center;
        &.category{
            // text-transform: uppercase;

        }
    }
    p{
        color:var(--grey-50);
        padding-bottom: 28px;
        font-size: 14px;
    }
}
.questionList{
    display: grid;
    padding:40px 32px;
    gap: 32px;
    // max-width: 1136px;
    margin:auto;
    @include devices(desktop){
        grid-template-columns: repeat(4,1fr);
    }
}
@import 'mixin.scss';


@keyframes slideUp {
    0%   {
        margin-top: 100px;
        opacity:0;
    }
    75% {
        opacity:1;
    }
    100% {
        margin-top: 0;
        opacity:1;
    }
}


.container{
    // padding-top: var(--section-gap-mobile);
    padding-bottom: var(--section-gap-mobile);
    text-align: center;
    animation-name: slideUp;
    animation-duration: .8s;
    display: grid;
    @include devices(desktop){
        padding: var(--section-wing);
        margin: auto;
        padding-top: var(--section-gap-desktop);
        padding-bottom: var(--section-gap-desktop);
        display: grid;
        max-width: var(--desktop-width);
        box-sizing: border-box;
        &.left_image{
            grid-template-columns: 1.7fr 1fr;
            grid-template-areas:'two-col-img-area two-col-desc-area' ;
            .description_area{
                padding-left: 40px;
                margin:auto;
            }
        }

        &.right_image{
            grid-template-columns: 1fr 1.7fr;
            grid-template-areas:'two-col-desc-area two-col-img-area ' ;
            align-items: center;
            .description_area{
                padding-right: 40px;
            }
        }

    }
    @include devices(mobileandtablet){
        display: grid;
        grid-template-areas:'two-col-img-area'
        'two-col-desc-area' ;
    }

   & h2{
        font-size: 40px;
        line-height: 1.3;
        letter-spacing: -.75px;
        margin-bottom: var(--element-gap);
        @include devices(tabletanddesktop){
            max-width: 527px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    p{
        font-size: var( --p-size);
        line-height: 1.5;
        font-weight: 500;
        color: var(--grey-50);
    }
    a {
        color: var(--primary-100);
        padding: 12px 0;
        font-weight: 700;
        display: block;
    }
}

.removepaddingbottom{
    padding-bottom: 0;
}


.image_area{
    background-color: var(--peach-500);
    position: relative;
    grid-area: two-col-img-area;
    img{
        max-height: 420px;
    }

    @include devices(mobileandtablet){
        padding: var(--section-wing);
    margin: 32px 0;
        margin-bottom: 100px;
    }
    @include devices(desktop){
        border-radius: 20px;
        // height: 520px;
    }

}

.image_description_container{
    max-width: 520px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: var(--primary-100);
    color: var(--white);
    padding: 30px;
    box-sizing: border-box;
    text-align: left;
    grid-gap: 16px;
    border-radius: 8px;
    h2{
        margin-bottom: 0;
        font-size: 40px;
    }
    p{
        font-size: 16px;
        color: var(--white);
    }
    position: absolute;
    bottom: -75px;
    left: 30px;

    @include devices(mobileandtablet){
        bottom: -63px;
        margin: auto;
        left: 0;
        right: 0;
        max-width: 329px;
        padding: 20px;
        h4{
            font-size: 24px;
        }
        p{
            font-size: 16px;
        }
    }
}

.image{
    // max-width: 532px;
    margin: auto;
    display: grid;
    height: 100%;
    align-items: center;
    justify-content: center;
    img {
        border-radius: 16px;
        @include devices(mobileandtablet){
            width: 100%;
        }
    }
}

.description_area{
    text-align: left;
    grid-area: two-col-desc-area;

    @include devices(mobileandtablet){
        padding: var(--section-wing);
    }
    h2{
        max-width: unset;
        line-height: .95;
        margin-bottom: 16px;
        letter-spacing: -2.5px;
        span{
            letter-spacing: -.5px;
        }
    }
    h3{
        letter-spacing: -1px;
    }
    p{
        padding-bottom: 24px;
    }
}

.image_full{
    max-width: 100%;
    padding:0;
    margin: 0;
    overflow: hidden;
    border-radius: 12px;
    overflow: hidden;
    max-height: 520px;
    img {
        width: 100%;
        max-height: unset;
    }
}

.light{
    h4{ 
        color: #FFF;
    }
}
@import 'mixin.scss';

.frozePage{
    overflow: hidden;
    max-height: 100vh;
}
.page{
    background-color: var(--white);
}

.mainDrawerNav{
    width: -webkit-fill-available;
    position: fixed;
    // height: calc(100vh - var(--nav-height));
    // top: var(--nav-height);
    top:72px;
    left: 0;

    z-index: 2;
    @include devices(desktop){
        width: 100%;
    top:0;
    display: grid;
        grid-template-columns: 400px auto;
    }
    ul{
        padding: 40px 0;
    }
}

.drawerNav{
    width: 100%;
    background-color: var(--white);
    // height: calc(100vh - var(--nav-height));
    padding-top: var(--element-gap);;
    height: calc(100vh - 72px);
    overflow: auto;
    box-shadow: rgba(99, 99, 172, .25) 0px 22px 12px -5px, rgba(0, 0, 0, 0.1) 8px 18px 3px 4px;
    @include devices(desktop){
        max-width: 400px;
        height: calc(100vh);
    }
    @include devices(mobile){
        padding-top: 0;
    }
}

.navTile{
    padding: var(--section-wing);
    color: var(--navy-1000);
    margin-bottom: 24px;
    display: block;
    h2{
        font-size: var(--semiregular-text) ;
        font-weight: 700;
        line-height: 1.2;
        letter-spacing: -.3px;
    }
    header{
        display: grid;
        grid-template-columns: auto 32px;
        align-items: center;
    }

    p{
        font-size: 14px;
        font-weight: 500;
        color:var(--grey-50);
        line-height: 1.5;
        letter-spacing: -.2px;
        max-width: calc(100% - 32px);
        display: none;
        
    }
    height: 32px;
    @include devices(mobileandtablet){
        height: unset;

        p{
            display: block;
        }
    }

    &:hover{
        height: 68px;
        padding-left: 30px;
        border-left: 10px solid;
        border-color:var(--grey-100);
        transition: border .5s;
        transition: height .5s;
        cursor: pointer;
        p{
            display: unset;
            opacity: 1;
            transition: opacity 1s;
            animation: fadeIn;
            animation-duration: .75s;
            padding-top: 8px;
            display: block;
        }
    }
}

.navTile_mobile{
    padding:0 20px;
    margin-left: 40px;
    border-left: 4px solid;
    margin-bottom: 20px;

    color: var(--navy-1000);
    // margin-bottom: 24px;
    display: block;
    h2{
        font-size: 14px ;
        font-weight: 700;
        line-height: 1.2;
        letter-spacing: -.3px;
    }
    header{
        margin-bottom: 8px;
        h2{
            font-size: 14px;
        }
    }
    p{
        font-size: 14px;
        font-weight: 500;
        color:var(--grey-50);
        line-height: 1.5;
        letter-spacing: -.2px;
        max-width: calc(100% - 32px);

    }
}
@keyframes fadeIn {
    0% {opacity: 0;padding-left: 20px;}
    50% {opacity: 0;padding-left: 20px;}
    100% {opacity: 1;padding-left: 0;}
  }
.drawerNavPopularTemplate{
    border-top: 2px solid;
    border-color: var(--primary-10);
    padding: var(--element-gap) 0 0;
    box-sizing: border-box;
    h2 {
        font-size: var(--p-size);
        text-transform: uppercase;
        letter-spacing: 1px;
        padding: 0 40px 20px;
        color:var(--grey-50);
    }
}

.drawerNavItemArrow{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    box-sizing: border-box;
    border: 1px solid var(--primary-100-10);
    border-radius: var(  --small-border-radius);
    svg{
        fill: var(--primary-100);
    }
}

.drawerNavRight{
    backdrop-filter: blur(8px);
    cursor: pointer;
    opacity: 1;
    visibility: visible;
    background-color: rgba(255,255,255,.35);
    @include devices(mobile){
        display: none;
    }   
}

.popularTemplateList{
    padding: var(--section-wing);
    padding-top: 0;
    padding-bottom:var(--element-gap) ;
    display: grid;
    overflow: auto;
    grid-auto-flow: column;
    column-gap: 20px;
    grid-auto-columns: max-content;
    align-items: center;
    scroll-snap-type: x mandatory;

}
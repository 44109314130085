@import 'mixin.scss';

.container{
    padding: var(--section-wing);
    padding-top: var(--section-gap-mobile);
    padding-bottom: var(--section-gap-mobile);
    text-align: center;
    @include devices(desktop){
        max-width: 738px;
        margin: auto;
        padding-top: var(--section-gap-desktop);
        padding-bottom: var(--section-gap-desktop);
    }

    h2{
        // font-size: var(--h1-size);
        font-size: 40px;
        line-height: .9;
        letter-spacing: -3px;
        margin-bottom: var(--element-gap);
        @include devices(tabletanddesktop){
            max-width: 680px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    h3{
        font-size: var( --p-size);
        opacity: 1;
        margin-bottom: var(--element-gap);
    }

    p{
        font-size: var( --p-size);
        line-height: 1.5;
        font-weight: 500;
        color: var(--white-50);
    }
}
.containerHero{
    padding: var(--section-wing);
    padding-top: var(--section-gap-mobile);
    padding-bottom: var(--section-gap-mobile);
    text-align: left;
    z-index: 0;
    .containerHero__content{
        height: 50vh;
        display: grid;
        align-items: end;
        p, h3{
            color: var(--white-50)
        }
        h1, h2{
            color: var(--white)
        }
    @include devices(desktop){
        // margin: auto;
        grid-template-columns: 3fr 1fr;
        h1{
            font-size: 88px;
        }
    }
    }
    @include devices(desktop){
        // margin: auto;
        grid-template-columns: 3fr 1fr;
        h1{
            font-size: 88px;
        }
    }

    h1{
        font-size: 58px;
        line-height: 1;
        letter-spacing: -3px;
        margin-bottom: var(--element-gap);
        margin-left: auto;
        margin-right: auto;
        @include devices(tabletanddesktop){
            // max-width: 720px;
        }
    }

    h2{
        font-size: var( --p-size);
        opacity: 1;
        margin-bottom: var(--element-gap);
    }

    p{
        font-size: var( --p-size);
        line-height: 1.5;
        font-weight: 500;
        color: var(--grey-50);
    }
}

.hero{
    padding-top: 152px;
    padding-bottom: 228px;
    position: relative;
    &::after{
        content: '';
        background-color: var(--white);
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100vw;
        height: 80px;
        border-radius: 60px 60px 0 0;
    }
    h2 {
        color: var(--white-50);
        letter-spacing: 2px;
        text-transform: uppercase;
        margin-bottom: 20px;
    }
    h1 {
        // margin-left: 0;
    }
    &.twocol{
        display: grid;
        grid-template-columns: 3fr 2fr;
        text-align: left;
        align-items: center;
        @include devices(desktop){
            // max-width: var(--desktop-width);
        }
        @include devices(mobileandtablet){
            grid-template-columns: unset;
            img{
                width: 100%;
            }
        }
    }
}
.cta{
    margin-top: var(--element-gap);
    display: flex;
    justify-content: center;
    
}
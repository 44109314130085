/* _GDPR.module.scss */
@import 'mixin.scss';

.content {
    background-color: rgba(188, 188, 188, 0.35);
    backdrop-filter: blur(42px);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    padding: 12px 20px;
    text-align: center;
    color: #333;
    @include devices(mobileandtablet) {
        display: grid;
        gap: 16px;
        padding: 24px 20px;
      }

    p {
      margin: 0;
      font-weight: bold;
      font-size: 16px;
      color: rgba(51, 51, 51, 0.7);
  
      a {
        color: #007bff;
        text-decoration: underline;
      }
    }
  
    button {
      margin-right: 10px;
      padding: 8px 16px;
      border-radius: 8px;
      font-family: Archia;
      font-size: 16px;
      font-weight: bold;
      height: 40px;
      cursor: pointer;
  
      &.accept {
        background-color: rgb(51, 51, 51);
        color: #fff;
        border: none;
      }
  
      &.dismiss {
        color: rgb(51, 51, 51);
        background-color: transparent;
        border: 2px solid rgb(51, 51, 51);
      }
    }
  }
  